import { memberPath } from "./path/path";

export enum MEMBER_TYPE {
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
}

export enum AUTH_METHODS {
  GOOGLE = "Google",
  FACEBOOK = "Facebook",
  APPLE = "Apple",
  CUSTOM = "Custom",
}

export enum LikeDislike {
  LIKE = "LIKE",
  DISLIKE = "DISLIKE",
  NEUTRAL = "NEUTRAL",
}

export enum CHECKOUT_STATUS {
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  ACCOUNT_SETUP = "ACCOUNT_SETUP",
  SUBSCRIPTION = "SUBSCRIPTION",
  PIN_SETUP = "PIN_SETUP",
  COMPLETED = "COMPLETED",
}

export const enum MEMBER {
  CUSTOMER_PERSONAL = "CUSTOMER_PERSONAL",
  CUSTOMER_BUSINESS = "CUSTOMER_BUSINESS",
}

export enum ENUM_MEMBER_ROLE {
  CUSTOMER_PERSONAL = "CUSTOMER_PERSONAL",
  CUSTOMER_BUSINESS = "CUSTOMER_BUSINESS",
}

// CHANGE_PASSWORD = 'CHANGE_PASSWORD',
// COMPLETED = 'COMPLETED',

export const ONBOARDING_STATUS_PATH = {
  "Email Verification": memberPath.emailVerification,
  EMAIL_VERIFICATION: memberPath.emailVerification,
  "Account Setup": memberPath.profileSetup,
  ACCOUNT_SETUP: memberPath.profileSetup,
  SUBSCRIPTION: memberPath.subscription,
  PIN_SETUP: memberPath.pinSetup,
};

export enum UNIQUE_FIELDS_CHECK {
  USERNAME = "userName",
  PHONE_NUMBER = "phoneNumber",
  BUSINESS_NAME = "businessName",
  EMAIL = "email",
}

export enum ENUM_OFFER_CATEGORY {
  PRODUCT = "Product",
  SERVICE = "Services",
  VEHICLE = "Cars & Vehicles",
  VACATION = "Vacation Rentals",
}

export enum ENUM_REASON_LIST {
  SUSPICIOUS = "SUSPICIOUS",
  ITS_SPAM = "ITS_SPAM",
  ADULT_CONTENT = "ADULT_CONTENT",
  FALSE_INFORMATION = "FALSE_INFORMATION",
  MY_INTELLECTUAL_PROPERTY = "MY_INTELLECTUAL_PROPERTY",
  OFFENSIVE_OR_INAPPROPRIATE = "OFFENSIVE_OR_INAPPROPRIATE",
  OTHER = "OTHER",
}

export enum ENUM_SERVICE_FULFILLMENT_PLACE {
  AT_MY_LOCATION = "At my location",
  AT_CUSTOMER_LOCATION = "At customer location",
  VIRTUAL = "Virtual",
  ALL_OF_THE_ABOVE = "All of the above",
}
export enum OFFER_STATUS {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  DELETE = "DELETE",
  ARCHIVE = "ARCHIVE",
  DEACTIVATED = "DEACTIVATED",
  DISABLED = "DISABLED",
  FAVOURITE = "FAVOURITE",
}

export enum ENUM_SUBSCRIPTION_STATUS {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
}

export enum ENUM_VEHICLE_FEATURE {
  SUNROOF = "Sunroof",
  AIR_CONDITIONING = "Air conditioning",
  ALLOY_WHEELS = "Alloy wheels",
  NAVIGATION_SYSTEM = "Navigation system",
  CRUISE_CONTROL = "Cruise control",
  BLUETOOTH = "Bluetooth",
  TRAILER_HITCH = "Trailer Hitch",
}

export enum ENUM_OFFER_STATUS {
  DRAFT = "DRAFT",
  ARCHIVE = "ARCHIVE",
  DISABLED = "DISABLED",
  PAUSED = "PAUSED",
  ACTIVE = "ACTIVE",
  DELETE = "DELETE",
}

export enum ENUM_TRANSACTION_TYPE {
  ADD_BITS = "ADD_BITS",
  GIFT = "GIFT",
  GIFT_REWARD = "GIFT_REWARD",
  SALE_PURCHASE = "SALE_PURCHASE",
  REWARDS = "REWARDS",
  REFUND = "REFUND",
  TRANSFER = "TRANSFER",
  UNKNOWN = "UNKNOWN",
}

export enum BREADCRUMB_TEXT {
  ADD_BITS = "Load BITS™",
  REWARDS = "Rewards",
  GIFT = "Gift",
  GIFT_REWARD = "RW-System",
  SALE_PURCHASE = "Sale",
  REFUND = "Refund",
  TRANSFER = "Transfer",
  UNKNOWN = "UNKNOWN",
}

export enum SEARCH_FILTER_CATEGORY {
  ALL = "1,2,3,4",
  PRODUCT = "1",
  SERVICES = "2",
  VACATION = "3",
  VEHICLES = "4",
}

export enum TRANSACTION_TYPE_DETAILS {
  SALE_PURCHASE = "Barter",
  ADD_BITS = "Load BITS™",
  GIFT_REWARD = "RW-System",
  GIFT = "Gift",
  REWARDS = "RW-Invite",
  REFUND = "Refund",
  TRANSFER = "Transfer",
}

export enum CHAT_ACTION {
  GET_USER = "getUser",
  GET_MUTE_USER = "getMuteUser",
  GET_BAN_USER = "getBanUser",
  GET_OPERATORS = "getOperators",
  GET_MEMBERS = "getMembers",
  GET_CHANNEL_DETAILS = "getChannelDetails",
  LEAVE_GROUP = "leaveGroup",
  FREEZE_CHANNEL = "freezeChannel",
  UNBAN_MEMBER = "unBanMember",
  UNMUTE_MEMBER = "unMuteMember",
  BAN_MEMBER = "banMember",
  MUTE_MEMBER = "muteMember",
  CREATE_CHANNEL = "createChannel",
  INVITE_MEMBER = "inviteMember",
  SEND_DEFAULT_MESSAGE = "sendDefaultMessage",
  REGISTER_AS_OPERATOR = "registerAsOperator",
  UNREGISTER_AS_OPERATOR = "unRegisterAsOperator",
}
